import styled from "@emotion/styled";
import { css } from "@emotion/react";

import ApplyIcon from "../../assets/svg/apply.inline.svg";
import CloseIcon from "../../assets/svg/close.inline.svg";

export const CloseButton = styled(CloseIcon)`
  /* fill: #b7b7b7; */
  width: 25px;
  height: 25px;
`;

export const closeButton = css`
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: 10px;
  left: 10px;
  @media (max-width: 1365px) {
    top: 10px;
  }
  @media (max-width: 768px) {
    top: 0;
    right: 0;
    padding: 12px;
  }
  @media (max-width: 550px) {
    top: 15px;
  }
`;

export const ApplyButton = styled(ApplyIcon)`
  width: 130px;
  margin-left: 20px;
  /* font-family: "Roboto"; */
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.3s, visibility 0.3s;
  &.visible {
    opacity: 0;
    visibility: hidden;
  }
  @media (max-width: 768px) {
    padding-top: 17px;
  }
  @media (max-width: 550px) {
    align-self: flex-end;
    width: 110px;
  }
`;

export const ListCareers = styled.div`
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 18vw;
  z-index: 1;
  display: flex;
  /* transition: filter 0.3s; */
  .kti-row.state-expanded & {
    /* filter: blur(20px); */
  }
  .ScrollbarsCustom-Content {
    flex-flow: column;
  }
  @media (max-width: 1365px) {
    width: 265px;
    right: 50px;
  }
  @media (max-width: 1365px) and (min-width: 769px) {
    .open-position & {
      visibility: hidden;
      opacity: 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    right: initial;
    transform: none;
    bottom: 0;
    padding: 15px 15px 35px;
    flex-flow: column;
    position: relative;
    margin-top: -50px;
    .open-position & {
      .no-scroll-zone {
        visibility: hidden;
      }
    }
    .last-row & {
      padding-bottom: 70px;
    }
  }
`;

export const careersTitle = css`
  text-transform: uppercase;
  color: #fff;
  font-size: 30px;
  margin: 15px 0 10px;
  flex: 1;
`;

export const careerLink = css`
  color: #fff;
  display: block;
  font-weight: 700;
  font-size: 18px;
  margin: 10px 0;
  cursor: pointer;
  transition: color 0.3s;
  &.active {
    color: #ba0027;
  }
  @media (hover: hover) {
    &:hover,
    &:focus {
      color: #ba0027;
    }
  }
`;

export const posLocation = css`
  display: block;
  font-size: 16px;
  font-weight: 400;
`;

export const positionHeader = css`
  display: flex;
  @media (max-width: 550px) {
    flex-flow: column;
  }
`;

export const SingleCareer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: #fff;
  transition: transform 0.6s;
  &.open-position {
    transform: translateX(100%);
  }
  @media (min-width: 1366px) {
    .close-button {
      display: none;
    }
  }
  @media (max-width: 1365px) {
    z-index: 3;
  }
  @media (max-width: 768px) {
    opacity: 1;
    align-items: flex-start;
    &.open-position {
      position: relative;
    }
  }
`;

export const PositionWrap = styled.div`
  width: calc(683px + (100vw - 1366px) * 0.61);
  margin-left: calc(236px + (100vw - 1366px) * 0.058);
  height: 67vh;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;
  @media (max-width: 1365px) {
    width: 100%;
    padding: 15px;
    margin: 80px 0 0;
    height: calc(100vh - 80px);
    height: 100%;
    margin: 0;
  }
  @media (max-width: 768px) {
    height: 100%;
    margin-top: 0;
    padding-top: 45px;
  }
  @media (max-width: 550px) {
    padding-top: 25px;
  }
`;

export const posDescrpition = css`
  padding-right: 20px;
  line-height: 1.5;
`;

export const posTitle = css`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  @media (max-width: 768px) {
    margin: 0;
    flex: 1;
  }

  @media (max-width: 550px) {
    font-size: 22px;
    padding-right: 25px;
  }
`;

export const ApplicationForm = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 31%;
  background-color: #fff;
  transition: transform 0.5s;
  z-index: 4;
  padding: 50px calc(12px + (100vw - 1366px) * 0.054) 107px;
  display: flex;
  flex-flow: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.27058823529411763);
  &.visible {
    transform: translateX(-100%);
  }
  @media (max-width: 1365px) {
    width: 420px;
    padding: 50px 15px;
    max-width: 100%;
  }
  @media (max-width: 768px) {
    position: relative;
    transform: none;
    padding: 0 8px 85px;
    left: 0;
    display: none;
    width: 100%;
    &.mob-active {
      display: block;
    }
    .close-button {
      display: none;
    }
    form {
      width: 100%;
    }
  }
`;
export const cfTitle = css`
  font-size: 30px;
  color: #c1c1c1;
  text-transform: uppercase;
  margin: 0 0 10px;
`;

export const FormLabel = styled.label`
  // position: absolute;
  margin-bottom: 6px;
  display: block;
`;

export const FormLabelName = styled.span`
  // position: absolute;
  // left: 10px;
  // top: 12px;
  font-weight: bold;
  font-size: 16px;
`;

export const formField = css`
  margin-bottom: 15px;
  position: relative;
  // display: flex;
  // height: 45px;
`;

export const mobFormField = css`
  @media (max-width: 480px) {
    flex-flow: column;
    .send-wrap {
      align-self: flex-end;
    }
  }
`;

export const formFieldBorder = css`
  border-radius: 10px;
  border-bottom-right-radius: 0;
  background-color: #fff;
`;

export const buttonSubmit = css`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 25px;
  background-color: #e6e6e6;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: bold;
  transition: color 0.3s;
  margin: 15px 0;
  &:hover,
  &:focus {
    color: #ba0027;
  }
`;

export const formElement = css`
  outline: none;
  width: 100%;
  // border: none;
  border-radius: 10px;
  background: #e6e6e6;
  border: 1px solid #ececec;
`;

export const formInput = css`
  height: 43px;
  line-height: 43px;
  padding-left: 5px;
`;

export const formTextarea = css`
  resize: none;
  height: calc(130px + (100vw - 1366px) * 0.0957);
  padding: 5px;
`;

export const checkboxLabel = css`
  flex: 1;
  line-height: 1.5;
  padding-left: 4px;
  a {
    text-decoration: underline;
    color: #ba0027;
  }
`;

export const ErrorField = styled.span`
  // position: absolute;
  color: #ba0027;
  top: calc(100% + 4px);
  left: 0;
  padding-left: 10px;
  font-size: 12px;
`;

export const uploadFile = css`
  color: #fff;
  background-color: #e6e6e6;
  text-align: center;
  cursor: pointer;
  width: 177px;
  border-radius: 10px;
  height: 35px;
  line-height: 35px;
`;

export const formInputFile = css`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const formLabelUpload = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 400px) {
    flex-flow: column;
    align-items: flex-start;
    span {
      margin-bottom: 5px;
    }
  }
`;

export const messageSent = css`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  &.sent-successfuly {
    opacity: 1;
    visibility: visible;
  }
`;

export const messageSentContainer = css`
  font-size: 28px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
`;
